.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.invisible {
  visibility: hidden;
}
.static {
  position: static;
}
.\!m-0 {
  margin: 0px !important;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.\!mt-4 {
  margin-top: 1rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.h-14 {
  height: 3.5rem;
}
.h-9 {
  height: 2.25rem;
}
.h-full {
  height: 100%;
}
.min-h-\[24rem\] {
  min-height: 24rem;
}
.\!w-full {
  width: 100% !important;
}
.w-\[150px\] {
  width: 150px;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.\!max-w-full {
  max-width: 100% !important;
}
.flex-1 {
  flex: 1 1 0%;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.resize-y {
  resize: vertical;
}
.resize {
  resize: both;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-x-3 {
  column-gap: 0.75rem;
}
.gap-x-4 {
  column-gap: 1rem;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}
.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}
.overflow-auto {
  overflow: auto;
}
.\!rounded-none {
  border-radius: 0px !important;
}
.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.border {
  border-width: 1px;
}
.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}
.border-b-0 {
  border-bottom-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.border-solid {
  border-style: solid;
}
.\!border-none {
  border-style: none !important;
}
.\!border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}
.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}
.bg-\[\#773eb1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(119 62 177 / var(--tw-bg-opacity));
}
.bg-\[\#f1ecf7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 236 247 / var(--tw-bg-opacity));
}
.bg-\[\#f3f8fb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 248 251 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.\!p-0 {
  padding: 0px !important;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.italic {
  font-style: italic;
}
.text-\[\#773eb1\] {
  --tw-text-opacity: 1;
  color: rgb(119 62 177 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.outline {
  outline-style: solid;
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
:root {
  // DEFAULT
  --purple-default: #773eb1;
  --text-white: #ffffff;
  --green-low-opacity: #daf2e4;
  --green-high-opacity: #27ae60;
  --page-background: #f3f8fb;

  // POPUPS
  --popup-header-background: #f1ecf7;

  // BUTTONS
  // GREEN BUTTONS
  --button-green-normal: #45be78;
  --button-green-hover: #54cb86;

  // BLUE BUTTONS
  --button-blue-normal: #4187cd;
  --button-blue-hover: #539ae2;

  // PURPLE BUTTONS
  --button-purple-normal: #773eb1;
  --button-purple-hover: #9052ce;
  --button-purple-active: #8551b9;

  --button-secondary-normal: #773eb11a;
  --button-secondary-hover: #773eb133;

  --button-secondary-text: #773eb1;

  // INPUTS
  --text-default: #383e50;
  --text-hover: #773eb1;
  --text-accent: #f2c94c;
  --text-blue: #1888da;

  // TOGGLES
  // TOGGLE NORMAL
  --toggle-normal-active-color: #ffffff;
  --toggle-normal-active-background: #773eb1;

  --toggle-normal-inactive-color: #bb9fd8;
  --toggle-normal-inactive-background: #ffffff;

  // TOGGLE HOVER
  --toggle-hover-active-color: #ffffff;
  --toggle-hover-active-background: #9052ce;

  --toggle-hover-inactive-color: #bb9fd8;
  --toggle-hover-inactive-background: #ffffff;

  // TOGGLE PRESSED
  --toggle-pressed-active-color: #ffffff;
  --toggle-pressed-active-background: #bb9fd8;

  --toggle-pressed-inactive-color: #bb9fd8;
  --toggle-pressed-inactive-background: #ffffff;

  // TABS
  --tab-inactive-color: #3e4143;
  --tab-active-color: #000000;
  --tab-border-color: #773eb1;

  --selected-tab: #9d74c7;

  // SCROLLS
  --scroll-normal: #773eb1;
  --scroll-hover: #9761cf;
  --scroll-pressed: #69349f;
  --scroll-track: #773eb133;

  // CARDS
  --card-header: #1888da;
  --card-label: #8f919a;
  --card-data: #383e50;
  --card-border: #e2e1ea;

  // OTHERS
  --table-header: #ecf7ff;
}
@font-face {
  font-family: "text-security";
  src: url("./assets/fonts/text-security-disc-compat.eot");
  src: url("./assets/fonts/text-security-disc-compat.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/text-security-disc-compat.woff2") format("woff2"),
  url("./assets/fonts/text-security-disc-compat.ttf") format("truetype");
}
html,
body {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  touch-action: manipulation;
}
*:focus {
  outline: none;
}
body {
  background-color: var(--page-background);
  overflow-x: hidden;
  overflow-y: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  margin: min(5vw, 20px) 0;
}
h1 {
  font-size: min(5vw, 2em);
}
h2 {
  font-size: min(5vw, 1.5em);
}
h3 {
  font-size: min(5vw, 1.17em);
}
h4 {
  font-size: min(5vw, 1em);
}
ul, ol {
  padding-inline-start: 40px;
}
input[type="radio"] {
  appearance: none;
  background: url(/assets/Radio/radio-1-norm.svg) no-repeat 100%;
  border: none;
}
input[type="radio"]:hover {
  background: url(/assets/Radio/radio-1-hover.svg) no-repeat 100%;
}
input[type="radio"]:active {
  background: url(/assets/Radio/radio-1-pressed.svg) no-repeat 100%;
}
input[type="radio"]:checked {
  background: url(/assets/Radio/radio-1-checked.svg) no-repeat 100%;
}
input[type="radio"]:disabled {
  background: url(/assets/Radio/radio-1-disabled.svg) no-repeat 100%;
}
input[type="checkbox"] {
  appearance: none;
  background: url(/assets/Checkbox/ch-box-1-norm.svg) no-repeat 100%;
  border: none !important;
}
input[type="checkbox"]:hover {
  appearance: none;
  background: url(/assets/Checkbox/ch-box-1-hover.svg) no-repeat 100%;
}
input[type="checkbox"]:checked {
  appearance: none;
  background: url(/assets/Checkbox/ch-box-1-checked.svg) no-repeat 100%;
}
input[type="checkbox"]:disabled {
  appearance: none;
  background: url(/assets/Checkbox/ch-box-1-disabled.svg) no-repeat 100%;
}
input[type="checkbox"]:active {
  appearance: none;
  background: url(/assets/Checkbox/ch-box-1-pressed.svg) no-repeat 100%;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--scroll-track);
}
::-webkit-scrollbar {
  width: 12px;
  background-color: var(--scroll-track);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scroll-normal);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-hover);
}
::-webkit-scrollbar-thumb:active {
  background: var(--scroll-pressed);
}
.page {
  margin: clamp(5px, 5vw, 20px);
}
.page-wrap {
  padding: 15px 30px;
  margin: 10px 5px;

  @media (max-width: 750px) {
    padding: 15px 10px;
    margin: 0 0 30px 0;
  }
}
.overlay-dark-background {
  background: rgba(0, 0, 0, 0.8);
}
.backdrop-blur {
  -webkit-backdrop-filter: blur(18px);
          backdrop-filter: blur(18px);
  background: rgba(0, 0, 0, 0.32);
}
.button-default {
  border: none;
  border-radius: 6px;
  line-height: 16px;
  font-weight: bold;
  padding: 10px 26px;
  color: var(--text-white);
  background-color: var(--button-purple-normal);
  text-decoration: none;
  text-align: center;
}
.button-default:hover {
  background-color: var(--button-purple-hover);
}
.button-secondary {
  border: none;
  border-radius: 6px;
  line-height: 16px;
  font-weight: bold;
  padding: 10px 26px;
  color: var(--button-secondary-text);
  background: var(--button-secondary-normal);
}
.button-secondary:hover {
  background: var(--button-secondary-hover);
}
.button-green {
  border: none;
  border-radius: 6px;
  line-height: 16px;
  font-weight: bold;
  padding: 10px 26px;
  color: var(--text-white);
  background: var(--button-green-normal);
}
.button-green:hover {
  background: var(--button-green-hover);
}
button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 0;
}
input, textarea, select {
  padding: 10px;
  border: 2px solid var(--card-border) !important;
  border-radius: 5px;
  resize: vertical;
}
select {
  background: url("/assets/Dropdown/arrow-b.svg") no-repeat 95%;
  appearance: none;
  background-color: var(--text-white);
}
select::-ms-expand {
  background: url("/assets/Dropdown/arrow-t.svg") no-repeat 95%;
}
button:disabled {
  opacity: 0.5;
}
a {
  color: var(--text-hover);
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
  text-decoration-color: var(--text-hover);
}
.success {
  padding: 20px;

  h2 {
    color: var(--text-hover);
    text-align: center;
  }

  h4 {
    color: var(--text-default);
    text-align: center;
  }
}
.google-button {
  background-color: #fdefef;
  color: #eb5757;
}
.google-button:hover {
  background-color: #ffdfdf;
}
.facebook-button {
  background-color: #effafe;
  color: #2f80ed;
}
.facebook-button:hover {
  background-color: #d6f4ff;
}
.twitter-button {
  background-color: #effafe;
  color: #56ccf2;
}
.twitter-button:hover {
  background-color: #d0d8df;
}
.signin-button {
  background: var(--button-blue-normal);
  color: var(--text-white);
}
.signin-button:hover {
  background: var(--button-blue-hover);
}
.signup-button {
  background: var(--button-green-normal);
  color: var(--text-white);
}
.signup-button:hover {
  background: var(--button-green-hover);
}
.purple-header {
  height: 200px;
  background: var(--purple-default);
}
.purple-header .image-container {
  display: block;
  width: 270px;
  margin: auto;
  padding-top: 2rem;
}
.purple-header .image-container img {
  width: 100%;
}
.login-copyright {
  font-size: 0.75rem;
  width: -moz-fit-content;
  width: fit-content;
  margin: 30px auto 10px;
  color: var(--text-default);
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 20px auto;
  background: white;
  border-radius: 25px;
}
.agree-checkbox {
  width: 70%;
  margin: 5px auto;
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.agree-checkbox input {
  width: unset !important;
  margin: unset !important;
}
.agree-checkbox label {
  margin-left: 5px;
  color: var(--text-default);
}
.social-media-buttons,
.inputs-container {
  display: flex;
  flex-direction: column;
}
.social-media-buttons button,
.inputs-container input,
.signin-button,
.password-field {
  margin: 5px auto;
  width: 70%;
}
.social-media-title,
.form-title,
.signup-title {
  text-align: center;
  margin: 20px;
  color: var(--text-default);
}
.social-media-title {
  font-size: 1.5rem;
}
.form-title {
  font-size: 1.25rem;
}
.register-link {
  background: var(--green-low-opacity);
  border-radius: 0 0 25px 25px;
  margin-top: 15px;
}
.error {
  width: 70%;
  margin: 15px auto;
  font-size: 0.8rem;
  text-align: center;
  color: var(--amplify-red);
  font-weight: bold;
  display: block;
}
.popup-container .mat-dialog-container {
  border-radius: 30px;
  padding: 0;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px;
  background: var(--popup-header-background);
}
.popup-header h3 {
  margin: 0;
  font-weight: 400;
  padding-left: 10px;
}
.no-shares {
  text-align: center;
  margin: 25px;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-accent);
}
.content-row {
  background: var(--page-background);
  border-radius: 6px;
  width: 90%;
  margin: 5px auto 5px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  input,
  .input-container {
    width: 40%;
  }

  .delete-icon img {
    cursor: pointer;
  }
}
.input-container {
  position: relative;

  .eye-icon {
    position: absolute;
    right: 3%;
    top: 12%;
    cursor: pointer;
  }

  input, textarea {
    width: 100%;
  }

}
.content-title-text {
  width: 90%;
  margin: 0 auto 0 0;
  padding: 0 10px;
  display: flex;

  span {
    width: 50%;
    font-size: 0.85rem;
  }
}
.mat-tab-body-content {
  padding-bottom: 44px !important;
}
app-new-share-bar {
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 10;
}
app-password-strength {
  width: 100%;
}
app-secure-paste {
  height: 100%;
}
hr {
  opacity: 0.35;
}
.create-share-icon {
  background: var(--purple-default);
  padding: 15px;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 100px;
}
// Share card styles
.shares-grid {
  display: grid;
  grid-template-columns: repeat(4, 260px);
  width: 100%;
  margin: auto;
  grid-gap: 25px;
}
.share-card {
  width: 265px;
  padding: 5px;
  border: 2px solid var(--card-border);
  background: var(--text-white);
  border-radius: 6px;
  cursor: pointer;
}
.share-card:hover {
  border: 2px solid var(--purple-default);
}
.share-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: 500;
  color: var(--text-blue);

  .title-text {
    width: 90%;
  }

  .copy {
    width: 10%;
  }
}
.share-date,
.expiration-date,
.protections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;

  .date-title {
    opacity: 0.5;
  }
}
@media only screen and (max-width: 1300px) {
  .shares-grid {
    grid-template-columns: repeat(3, 260px);
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 960px) {
  .shares-grid {
    grid-template-columns: repeat(2, 260px);
    grid-gap: 15px;
  }
}
@media only screen and (max-width: 750px) {
  .shares-grid {
    grid-template-columns: repeat(1, 100%);
    grid-gap: 10px;
  }

  .share-card {
    width: 100%;
  }

  .mat-tab-body-wrapper {
    margin: 20px;
  }
}
// END of share card styles
.input-type-selector {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  position: relative;

  .material-icons {
    font-size: 18px;
    color: var(--purple-default);
    font-weight: bold;
  }

  .menu-items {
    position: absolute;
    background: var(--page-background);
    width: 200px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    border-radius: 5px;

    .item-row {
      margin: 6px 0;
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--purple-default);
      cursor: pointer;
      text-transform: capitalize;
    }

    .item-row:hover {
      font-weight: 700;
    }
  }
}
.input-container {
  position: relative;

  mat-datepicker-toggle {
    position: absolute;
    right: 1.5%;
  }
}
.password-text {
  font-family: "text-security";
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc;
}
.regular-text {
  font-family: "Work Sans";
}
.profile-picture-container {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 40px;
}
.profile-picture-container-account {
  width: 140px;
  height: 140px;
  cursor: pointer;
  margin-right: 40px;
  position: relative;
}
.profile-image {
  width: 100%;
  border-radius: 100%;
  cursor: pointer;
}
.box {
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 1px;
  z-index: 5;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: var(--amplify-red);
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
.banner {
  width: 100%;
}
.banner div {
  padding: 5px 0px;
  background-color: var(--amplify-red);
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  display: block;
}
.pass-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--amplify-background-color);
  border-radius: 5px;
  border: 2px solid var(--card-border);

  input {
    border: unset !important;
    width: 90% !important;
  }
}
.eye-container {
  width: 20%;

  img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}
.delete-hidden {
  visibility: hidden;
  cursor: auto;
}
@media (max-width: 750px) {
  .create-share-icon {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .login-form {
    width: 80%;
  }

  .agree-checkbox {
    font-size: 0.7rem;
  }
}
.crs-access-denied {
  text-align: center;
}
.crs-table-wrapper {
  margin: 0 50px;

  .filters-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    select {
      width: 200px;
      margin-right: 10px;
    }
  }

  .search-container {
    border: 2px solid var(--card-border);
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #ffffff;

    span {
      color: var(--purple-default);
      font-weight: bold;
      padding-left: 5px;
    }

    input {
      border: none !important;
      border-radius: unset;
    }
  }

  table {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    border: 1px solid var(--card-border);
    box-sizing: border-box;
    border-radius: 6px;

    th,
    td {
      width: 20%;
      padding: 10px 6px;
      text-align: start;
    }

    th {
      font-size: 0.8rem;
      color: var(--text-default);
    }

    .table-header {
      background: var(--table-header);
    }

    .table-data {
      background: var(--text-white);
      font-size: 0.85rem;
      border: 1px solid var(--card-border);
    }
  }

  .actions-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    div, a {
      margin-right: 20px;
      display: inline;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .share-card {
    padding: 0px;
  }

  .share-date, .expiration-date, .protections {
    justify-content: unset;

    .date-title {
      flex-basis: 35%;
    }
  }

  .share-data {
    margin: 10px;
  }

  .share-title {
    margin: 10px;
    font-weight: 800;
    color: var(--card-data);
  }

  .share-protection-column {
    font-weight: 600;
    color: var(--purple-default);
  }

  .share-expiration {
    color: #eb5757;
    font-weight: 800;
  }

  .share-protection {
    color: #1888DA;
    font-weight: 800;
  }

  @media (max-width: 750px) {
    .filters-container {
      flex-direction: column;

      select {
        margin-right: 0;
      }

      select,
      .search-container,
      .search-container input {
        width: 100%;
      }
    }

    .filters-container > * + * {
      margin-top: 10px
    }

    .delete {
      margin-right: 0px;
    }

    .actions-container {
      width: auto;

      span {
        margin-right: 10px;
      }
    }

    .shares-grid {
      margin-top: 10px;
    }
  }
}
.mg-left-10 {
  margin-left: 10px;
}
.mg-right-10 {
  margin-right: 10px;
}
.ngx-mat-timepicker {
  form {
    justify-content: center;
    display: flex;
  }

  input {
    border: none !important;
  }

  .mat-form-field-underline {
    display: none;
  }
}
.notification-menu {
  min-width: 400px !important;
  min-height: auto !important;
  text-overflow: ellipsis;

  @media (max-width: 750px) {
    min-width: 100px !important;
  }
}
.popup-container {
  max-height: 100%;

  .left-side {
    width: auto !important;
  }

  .credentials-box {
    max-width: 100% !important;
    margin: 50px !important;
  }



  .confirm-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    background: var(--page-background);
    position: relative;
    bottom:0;

    button {
      margin-right: 10px;
    }
  }
}
/* HTML: <div class="spinner"></div> */
.spinner {
  height: 30px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.spinner::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}
@media (orientation: landscape) {
  .form-container, .success-container {
    max-height: 50vh;
  }
}
@media (orientation: portrait) {
  .form-container, .success-container {
    max-height: 60vh;
  }
}
@media (min-width: 1024px) {
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg\:ml-4 {
    margin-left: 1rem;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:grid {
    display: grid;
  }
  .lg\:w-\[200px\] {
    width: 200px;
  }
  .lg\:min-w-\[600px\] {
    min-width: 600px;
  }
  .lg\:flex-1 {
    flex: 1 1 0%;
  }
  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
